import React, { useState } from 'react';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
    LineShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
    LineIcon
} from 'react-share';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    TextField,
    IconButton
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

const ShareSection = ({ shareTitle, shareCode, open, onClose }) => {
    const url = `${process.env.REACT_APP_BASE_URL}/storyshare/${shareCode}`;
    const [copied, setCopied] = useState(false);

    const handleCopyLink = async () => {
        try {
            await navigator.clipboard.writeText(url);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (error) {
            console.error('Failed to copy link:', error);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: '20px',
                    backgroundColor: '#F0F0F5',
                    color: '#333',
                    padding: '20px',
                    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.1)'
                }
            }}
        >
            {/* 標題區塊 */}
            <DialogTitle
                sx={{
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#333',
                    marginBottom: '10px'
                }}
            >
                Share: <span style={{ color: '#007BFF' }}>{shareTitle}</span>
            </DialogTitle>

            {/* 分享連結與複製 */}
            <DialogContent>
                <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ textAlign: 'center', color: '#555', fontSize: '1.1rem' }}
                >
                    Share this link with your friends:
                </Typography>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                    sx={{
                        backgroundColor: '#E8E8F0',
                        borderRadius: '10px',
                        padding: '10px'
                    }}
                >
                    <TextField
                        fullWidth
                        value={url}
                        InputProps={{
                            readOnly: true,
                            sx: { color: '#333' }
                        }}
                        variant="outlined"
                        sx={{
                            borderRadius: '10px',
                            backgroundColor: '#E8E8F0',
                            border: 'none'
                        }}
                    />
                    <IconButton onClick={handleCopyLink} color={copied ? 'success' : 'primary'}>
                        {copied ? <CheckIcon /> : <ContentCopyIcon />}
                    </IconButton>
                </Box>

                {/* 分享平台圖示 */}
                <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ textAlign: 'center', color: '#555', fontSize: '1.1rem', fontWeight: 'bold' }}
                >
                    Share on Social Media:
                </Typography>
                <Box
                    display="flex"
                    justifyContent="center"
                    gap={3}
                    flexWrap="wrap"
                    sx={{ marginTop: '20px' }}
                >
                    <Box textAlign="center">
                        <FacebookShareButton url={url} quote={shareTitle}>
                            <FacebookIcon size={64} round />
                        </FacebookShareButton>
                        <Typography mt={1} sx={{ color: '#333' }}>Facebook</Typography>
                    </Box>

                    <Box textAlign="center">
                        <TwitterShareButton url={url} title={shareTitle}>
                            <TwitterIcon size={64} round />
                        </TwitterShareButton>
                        <Typography mt={1} sx={{ color: '#333' }}>Twitter</Typography>
                    </Box>

                    <Box textAlign="center">
                        <WhatsappShareButton url={url} title={shareTitle}>
                            <WhatsappIcon size={64} round />
                        </WhatsappShareButton>
                        <Typography mt={1} sx={{ color: '#333' }}>WhatsApp</Typography>
                    </Box>

                    <Box textAlign="center">
                        <EmailShareButton url={url} subject={shareTitle}>
                            <EmailIcon size={64} round />
                        </EmailShareButton>
                        <Typography mt={1} sx={{ color: '#333' }}>Email</Typography>
                    </Box>

                    <Box textAlign="center">
                        <LineShareButton url={url} title={shareTitle}>
                            <LineIcon size={64} round />
                        </LineShareButton>
                        <Typography mt={1} sx={{ color: '#333' }}>Line</Typography>
                    </Box>
                </Box>
            </DialogContent>

            {/* 關閉按鈕 */}
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button
                    onClick={onClose}
                    variant="contained"
                    sx={{
                        backgroundColor: '#007BFF',
                        color: '#FFF',
                        fontWeight: 'bold',
                        borderRadius: '20px',
                        padding: '10px 20px',
                        fontSize: '1rem',
                        '&:hover': {
                            backgroundColor: '#0056b3'
                        }
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ShareSection;
