import React, { useState, useEffect, useRef } from 'react';
import { Box, Pagination } from '@mui/material';
import CompleteCategorySection from './utils/CompleteCategorySection';
import ShareSection from './utils/ShareSection';
import { Document, Page, pdfjs } from 'react-pdf';
import FlipPage from 'react-flip-page';
import api from '../../services/apiService';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`;

function useWindowSize() {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const onResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return size;
}

const StoryListViewer = () => {
  const { t } = useTranslation();
  const [completedStories, setCompletedStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentCategoryPage, setCurrentCategoryPage] = useState(1);
  const [pdfUrl, setPdfUrl] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [shareTitle, setShareTitle] = useState('');
  const [shareCode, setShareCode] = useState('');
  const containerRef = useRef();
  const itemsPerPage = 5;
  const { width: winWidth, height: winHeight } = useWindowSize();

  const PDF_WIDTH = 1575
  const PDF_HEIGHT = 1181

  const scaleWidth = (isFullScreen ? winWidth : winWidth * 0.9) / PDF_WIDTH
  const scaleHeight = (isFullScreen ? winHeight : winHeight * 0.9) / PDF_HEIGHT
  const scale = Math.min(scaleWidth, scaleHeight);
  const scaledWidth = PDF_WIDTH * scale;
  const scaledHeight = PDF_HEIGHT * scale;


  const loadFile = async (filename) => {
    if (filename) {
      try {
        const response = await api.get(`/api/stories/getfile/${filename}`, {
          responseType: 'blob',
        });
        const fileBlob = response.data;
        return URL.createObjectURL(fileBlob);
      } catch (error) {
        console.error(t('error.loadFileFailed'), error);
        return '';
      }
    }
    return '';
  };

  useEffect(() => {
    const fetchCompletedStories = async () => {
      try {
        const response = await api.get('/api/stories/getCompletedStories');
        const stories = response.data;

        const storiesWithAssets = await Promise.all(
          stories.map(async (story) => {
            const coverImageUrl = await loadFile(story.coverImage);
            return { ...story, coverImageUrl, pdfFile: story.pdfFile };
          })
        );

        setCompletedStories(storiesWithAssets);
      } catch (error) {
        console.error(t('error.fetchStoriesFailed'), error);
      } finally {
        setLoading(false);
      }
    };
    fetchCompletedStories();
  }, [t]);

  const handleImageClick = async (pdfFile) => {
    try {
      const pdfUrl = await loadFile(pdfFile);
      setPdfUrl(pdfUrl);
      setIsFullScreen(true);
      if (containerRef.current) {
        containerRef.current.requestFullscreen().catch((err) => {
          console.error(t('error.fullscreenError'), err.message);
        });
      }
    } catch (error) {
      Swal.fire({
        title: t('common.error'),
        text: t('error.pdfLoadError'),
        icon: 'error',
        confirmButtonText: t('common.ok'),
      });
    }
  };

  const handleDownloadPdfClick = async (title, pdfFile) => {
    try {
      const pdfUrl = await loadFile(pdfFile);
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = `${title}.pdf`;
      link.click();
    } catch (error) {
      console.error(t('error.downloadFailed'), error);
    }
  };

  const handleBuyClick = async (storyListId) => {
    try {
      const result = await Swal.fire({
        title: t('confirm.paymentTitle'),
        text: t('confirm.paymentText'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('common.confirm'),
        cancelButtonText: t('common.cancel'),
      });

      if (result.isConfirmed) {
        const response = await api.post('/api/stories/confirmPayment', { storyListId });
        const { pdfFilename } = response.data;
        Swal.fire(t('payment.success'), t('payment.successMessage'), 'success');
        setCompletedStories((prevStories) =>
          prevStories.map((story) =>
            story.id === storyListId ? { ...story, is_paid: true, pdfFile: pdfFilename } : story
          )
        );
      }
    } catch (error) {
      Swal.fire(t('payment.failure'), t('payment.failureMessage'), 'error');
    }
  };

  const handleShareClick = (title, code) => {
    setShareTitle(title);
    setShareCode(code);
    setShareOpen(true);
  };

  useEffect(() => {
    const handleExitFullScreen = () => {
      if (!document.fullscreenElement) {
        setIsFullScreen(false);
      }
    };
    document.addEventListener('fullscreenchange', handleExitFullScreen);
    return () => {
      document.removeEventListener('fullscreenchange', handleExitFullScreen);
    };
  }, []);

  const indexOfLastItem = currentCategoryPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentStories = completedStories.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="container mx-auto p-6">
      {loading ? (
        <p className="text-center text-gray-500">{t('common.loading')}</p>
      ) : (
        <div>
          {currentStories.map((story) => (
            <CompleteCategorySection
              key={story.id}
              title={story.title}
              description={story.description}
              books={[{ imgSrc: story.coverImageUrl, title: story.title }]}
              pdfUrl={story.is_paid ? story.pdfUrl : null}
              price={story.is_paid ? null : story.price}
              isPaid={story.is_paid}
              updatedAt={story.updated_at}
              onDownloadPdfClick={() => handleDownloadPdfClick(story.title, story.pdfFile)}
              onImageClick={() => handleImageClick(story.pdfFile)}
              onBuyClick={() => handleBuyClick(story.id)}
              onShareClick={() => handleShareClick(story.title, story.shareCode)}
            />
          ))}

          <Box display="flex" justifyContent="center" alignItems="center" mb={8}>
            <Pagination
              count={Math.ceil(completedStories.length / itemsPerPage)}
              page={currentCategoryPage}
              onChange={(event, value) => setCurrentCategoryPage(value)}
              variant="outlined"
              shape="rounded"
            />
          </Box>

          {isFullScreen && pdfUrl && (
            <div
              ref={containerRef}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: isFullScreen ? '100%' : scaledWidth,
                height: isFullScreen ? '100%' : scaledHeight,
                margin: '0 auto',
                overflow: 'hidden'
              }}
            >
              <Document file={pdfUrl} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                <FlipPage
                  orientation="horizontal"
                  animationDuration={700}
                  flipOnTouch
                  width={scaledWidth}
                  height={scaledHeight}
                >
                  {Array.from({ length: numPages }, (_, index) => (
                    <div
                      key={index + 1}
                      style={{ width: scaledWidth, height: scaledHeight }}
                    >
                      <Page pageNumber={index + 1} scale={scale} />
                    </div>
                  ))}
                </FlipPage>
              </Document>
            </div>
          )}
          <ShareSection shareTitle={shareTitle} shareCode={shareCode} open={shareOpen} onClose={() => setShareOpen(false)} />
        </div>
      )}
    </div>
  );
};

export default StoryListViewer;
