import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Pagination } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import FlipPage from 'react-flip-page';
import api from '../../services/apiService';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`;

function useWindowSize() {
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const onResize = () => {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);

    return size;
}

const StoryShareViewer = () => {
    const { share_code } = useParams();
    const { t } = useTranslation();
    const [storyData, setStoryData] = useState(null);
    const [pdfUrl, setPdfUrl] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const containerRef = useRef();
    const { width: winWidth, height: winHeight } = useWindowSize();
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const PDF_WIDTH = 1575;
    const PDF_HEIGHT = 1181;

    const scaleWidth = (isFullScreen ? winWidth : winWidth * 0.9) / PDF_WIDTH;
    const scaleHeight = (isFullScreen ? winHeight : winHeight * 0.9) / PDF_HEIGHT;
    const scale = Math.min(scaleWidth, scaleHeight);
    const scaledWidth = PDF_WIDTH * scale;
    const scaledHeight = PDF_HEIGHT * scale;

    useEffect(() => {
        const fetchStory = async () => {
            try {
                const response = await api.get(`/api/stories/sharestory/${share_code}`);
                setStoryData(response.data);
            } catch (error) {
                Swal.fire(t('error.fetchStoriesFailed'), error.message, 'error');
            }
        };
        fetchStory();
    }, [share_code, t]);

    useEffect(() => {
        const handleExitFullScreen = () => {
            if (!document.fullscreenElement) {
                setIsFullScreen(false);
            }
        };
        document.addEventListener('fullscreenchange', handleExitFullScreen);
        return () => {
            document.removeEventListener('fullscreenchange', handleExitFullScreen);
        };
    }, []);

    const loadFile = async (filename) => {
        if (filename) {
            try {
                const response = await api.get(`/api/stories/getsharefile/${share_code}/${filename}`, {
                    responseType: 'blob',
                });
                const fileBlob = response.data;
                return URL.createObjectURL(fileBlob);
            } catch (error) {
                Swal.fire(t('error.loadFileFailed'), error.message, 'error');
                return '';
            }
        }
        return '';
    };

    const handleReadStoryClick = async () => {
        try {
            const pdfUrl = await loadFile(storyData.pdf_filename);
            setPdfUrl(pdfUrl);
            setIsFullScreen(true);
            if (containerRef.current) {
                containerRef.current.requestFullscreen();
            }
        } catch (error) {
            Swal.fire(t('error.pdfLoadError'), error.message, 'error');
        }
    };

    return (
        <div className="container mx-auto p-6">
            {storyData && (
                <Helmet>
                    <title>{storyData.title}</title>
                    <description>{storyData.description}</description>
                    <meta name="title" content={storyData.title} />
                    <meta name="description" content={storyData.description} />
                    <meta property="og:title" content={storyData.title} />
                    <meta property="og:description" content={storyData.description} />
                    <meta
                        property="og:image"
                        content={`${apiBaseUrl}/api/stories/getsharefile/${share_code}/${storyData.coverImage}`}
                    />
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="630" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="website" />
                </Helmet>
            )}

            {storyData ? (
                <div className="text-center">
                    <img
                        src={`${apiBaseUrl}/api/stories/getsharefile/${share_code}/${storyData.coverImage}`}
                        alt={storyData.title}
                        className="w-64 h-96 object-cover mx-auto"
                    />
                    <h2 className="text-3xl font-bold my-4">{storyData.title}</h2>
                    <h2 className="text-2xl font-bold my-4">{storyData.eng_title}</h2>
                    <p className="text-lg my-2">{storyData.description}</p>
                    <button
                        className="bg-blue-500 text-white py-2 px-6 rounded-full mt-4"
                        onClick={handleReadStoryClick}
                    >
                        {t('category.readStory')}
                    </button>
                </div>
            ) : (
                <p>{t('common.loading')}</p>
            )}

            {isFullScreen && pdfUrl && (
                <div
                    ref={containerRef}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: scaledWidth,
                        height: scaledHeight,
                        margin: '0 auto',
                        overflow: 'hidden'
                    }}
                >
                    <Document file={pdfUrl} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                        <FlipPage
                            orientation="horizontal"
                            animationDuration={700}
                            flipOnTouch
                            width={scaledWidth}
                            height={scaledHeight}
                        >
                            {Array.from({ length: numPages }, (_, index) => (
                                <div
                                    key={index + 1}
                                    style={{ width: scaledWidth, height: scaledHeight }}
                                >
                                    <Page pageNumber={index + 1} scale={scale} />
                                </div>
                            ))}
                        </FlipPage>
                    </Document>
                </div>
            )}
        </div>
    );
};

export default StoryShareViewer;
