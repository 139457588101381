import create from 'zustand';
import i18n from '../i18n'; // 引入 i18n

const languageStore = create((set) => ({
  language: localStorage.getItem('language') || 'zh', // 從 localStorage 讀取語言，默認為 'zh'
  initialized: false, // 判斷是否初始化過
  setLanguage: (newLanguage) => {
    localStorage.setItem('language', newLanguage); // 更新 localStorage 中的語言
    i18n.changeLanguage(newLanguage); // 同步更新 i18next 的語言
    set({ language: newLanguage, initialized: true }); // 更新狀態
  },
}));

export default languageStore;
