import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import AppRoutes from './routes/AppRoutes';
import LoginPage from './components/auth/LoginPage';
import StoryShareViewer from './components/share/StoryShareViewer';
import useAuthStore from './stores/authStore';

const App = () => {
  const checkAuth = useAuthStore((state) => state.checkAuth);

  // 應用初始化時檢查登入狀態
  useEffect(() => {
    checkAuth(); // 檢查登入狀態並更新狀態
  }, [checkAuth]);

  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="pt-20">
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/storyshare/:share_code" element={<StoryShareViewer />} />
            <Route path="/*" element={<AppRoutes />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
